import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const ticketing = [""];

const routes = [
 // {
 //   path: "/",
 //   name: "Home",
 //   component: Home,
 //   meta: {
 //     requiresAuth: true,
 //   },
 // },
 { path: "/", redirect: "/tickets" },
 {
  path: "/expert-statistics/full-report/:urlType",
  props: true,
  name: "ExpertStatisticsFullQueueReport",
  component: () => import("../views/PBX/ExpertStatisticsFullQueueReport.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 {
  path: "/expert-statistics/did-report",
  name: "ExpertStatisticsDIDReport",
  component: () => import("../views/PBX/ExpertStatisticsDIDReport.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 {
  path: "/dashboard",
  name: "Dashboard",
  component: () => import("../views/PBX/Dashboard.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["standard-statistics.view", "standard-statistics.*"],
  },
 },
 {
  path: "/profile",
  name: "Profile",
  component: () => import("../views/Profile.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/invoices",
  name: "Invoices",
  component: () => import("../views/Invoices.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["invoicing.view", "invoicing.*"],
  },
 },
 {
  path: "/contacts-routes",
  name: "ContactRoutesExpert3cx",
  component: () => import("../views/ContactRoutes.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["smart-routings.view", "smart-routings.*"],
  },
 },
 {
  path: "/contact-detail/:account/:id",
  name: "ContactDetailExpert3cx",
  component: () => import("../views/ContactDetail.vue"),
  props: true,
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["smart-routings.view", "smart-routings.*"],
  },
 },
 {
  path: "/contacts",
  name: "CustomerContacts",
  component: () => import("../views/CustomerContacts.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["contacts.view", "contacts.*"],
  },
 },
 {
  path: "/contact/:id",
  name: "CustomerContactDetail",
  component: () => import("../views/CustomerContactDetail.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["contacts.view", "contacts.*"],
  },
 },
 {
  path: "/tickets",
  name: "Tickets",
  component: () => import("../views/Tickets.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["ticketing.view", "ticketing.view-own", "ticketing.*"],
  },
 },
 {
  path: "/closed-tickets",
  name: "TicketsClosed",
  component: () => import("../views/ClosedTickets.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["ticketing.view", "ticketing.view-own", "ticketing.*"],
  },
 },
 {
  path: "/ticket/:id",
  name: "TicketDetailNew",
  component: () => import("../components/TicketDetail.vue"),
  props: true,
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["ticketing.view", "ticketing.view-own", "ticketing.*"],
  },
 },
 {
  path: "/documents",
  name: "Documents",
  component: () => import("../views/Documents.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["customers.view", "customers.*"],
  },
 },
 {
  path: "/web-radios",
  name: "WebRadios",
  component: () => import("../views/WebRadio/WebRadios.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["webradios.view", "webradios.*"],
  },
 },
 {
  path: "/customer-files",
  name: "Folder",
  component: () => import("../views/Collect/FoldersNew.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["information-collections.view", "information-collections.*"],
  },
 },
 {
  path: "/customer-files/collect/:customerFileId/:fileId/:formIndex",
  name: "Collect",
  props: true,
  component: () => import("../views/Collect/Collect.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["information-collections.edit.", "information-collections.*"],
  },
 },
 {
  path: "/customer-files/collectList/:fileId",
  name: "CollectList",
  component: () => import("../views/Collect/CollectList.vue"),
  props: true,
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["information-collections.view.", "information-collections.*"],
  },
 },
 {
  path: "/cti",
  name: "CTIExpert3cx",
  component: () => import("../views/CTI.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["smart-routings.view", "smart-routings.*"],
  },
 },
 {
  path: "/cti-detail/:account/:id",
  name: "CTIDetailExpert3cx",
  component: () => import("../views/CTIDetail.vue"),
  props: true,
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["smart-routings.view", "smart-routings.*"],
  },
 },
 {
  path: "/georouting",
  name: "GeoRoutingExpert3cx",
  component: () => import("../views/GeoRouting.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["smart-routings.view", "smart-routings.*"],
  },
 },
 {
  path: "/routinglist-detail/:account/:id",
  name: "RoutingListDetailExpert3cx",
  component: () => import("../views/RoutingListDetail.vue"),
  props: true,
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["smart-routings.view", "smart-routings.*"],
  },
 },
 {
  path: "/callqueues",
  name: "CallQueuesExpert3cx",
  component: () => import("../views/CallQueues.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["smart-routings.view", "smart-routings.*"],
  },
 },
 {
  path: "/callqueues/:account/:id",
  name: "CallQueuesDetailExpert3cx",
  component: () => import("../views/CallQueuesDetail.vue"),
  props: true,
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["smart-routings.view", "smart-routings.*"],
  },
 },
 {
  path: "/callqueues-groups",
  name: "CallQueuesGroupsExpert3cx",
  component: () => import("../views/CallQueuesGroups.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["smart-routings.view", "smart-routings.*"],
  },
 },
 {
  path: "/callqueues-groups/:account/:id",
  name: "CallQueuesGroupDetailExpert3cx",
  component: () => import("../views/CallQueuesGroupDetail.vue"),
  props: true,
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["smart-routings.view", "smart-routings.*"],
  },
 },
 {
  path: "/sms",
  name: "SMS",
  component: () => import("../views/SMS.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["sms.view", "sms.*"],
  },
 },
 /* {
  path: "/sms/configuration",
  name: "SMSConfiguration",
  component: () => import("../views/SMS/SMSConfiguration.vue"),
  meta: {
   requiresAuth: true,
   permissions: ["sms.edit", "sms.*"],
  },
  beforeEnter: (to, from, next) => {
   const services = store.state.services;
   const objService = services.find((service) => service.key === "sms");
   const userPermissions = store.state.userPermissions;

   if (objService) {
    const { enabled, can_be_enabled, key } = objService;
    if (
     enabled &&
     userPermissions &&
     userPermissions.permissions &&
     userPermissions.permissions.some((permission) => to.meta.permissions.includes(permission))
    ) {
     next();
    } else if (can_be_enabled) {
     next({ name: "EnableService", params: { serviceName: key } });
    } else {
     next({ name: "Unauthorized" });
    }
   } else {
    next({ name: "Unauthorized" });
   }
  },
 },
 {
  path: "/sms-detail/:account/:id",
  name: "SMSListDetail",
  component: () => import("../views/SMSListDetail.vue"),
  props: true,
  meta: {
   requiresAuth: true,
   permissions: ["sms.view", "sms.*"],
  },
  beforeEnter: (to, from, next) => {
   const services = store.state.services;
   const objService = services.find((service) => service.key === "sms");
   const userPermissions = store.state.userPermissions;

   if (objService) {
    const { enabled, can_be_enabled, key } = objService;
    if (
     enabled &&
     userPermissions &&
     userPermissions.permissions &&
     userPermissions.permissions.some((permission) => to.meta.permissions.includes(permission))
    ) {
     next();
    } else if (can_be_enabled) {
     next({ name: "EnableService", params: { serviceName: key } });
    } else {
     next({ name: "Unauthorized" });
    }
   } else {
    next({ name: "Unauthorized" });
   }
  },
 },
 {
  path: "/sms-recipients/:activeTab",
  name: "Recipients",
  props: true,
  component: () => import("../views/SMS/SMSRecipients.vue"),
  meta: {
   requiresAuth: true,
   permissions: ["sms.view", "sms.*"],
  },
  beforeEnter: (to, from, next) => {
   const services = store.state.services;
   const objService = services.find((service) => service.key === "sms");
   const userPermissions = store.state.userPermissions;

   if (objService) {
    const { enabled, can_be_enabled, key } = objService;
    if (
     enabled &&
     userPermissions &&
     userPermissions.permissions &&
     userPermissions.permissions.some((permission) => to.meta.permissions.includes(permission))
    ) {
     next();
    } else if (can_be_enabled) {
     next({ name: "EnableService", params: { serviceName: key } });
    } else {
     next({ name: "Unauthorized" });
    }
   } else {
    next({ name: "Unauthorized" });
   }
  },
 },
 {
  path: "/sms-campaign/:activeTab",
  name: "SMSCampaign",
  props: true,
  component: () => import("../views/SMSCampaign.vue"),
  meta: {
   requiresAuth: true,
   permissions: ["sms.view", "sms.*"],
  },
  beforeEnter: (to, from, next) => {
   const services = store.state.services;
   const objService = services.find((service) => service.key === "sms");
   const userPermissions = store.state.userPermissions;

   if (objService) {
    const { enabled, can_be_enabled, key } = objService;
    if (
     enabled &&
     userPermissions &&
     userPermissions.permissions &&
     userPermissions.permissions.some((permission) => to.meta.permissions.includes(permission))
    ) {
     next();
    } else if (can_be_enabled) {
     next({ name: "EnableService", params: { serviceName: key } });
    } else {
     next({ name: "Unauthorized" });
    }
   } else {
    next({ name: "Unauthorized" });
   }
  },
 },
 {
  path: "/sms-campaign/:activeTab/:id",
  name: "SMSCampaignDetail",
  props: true,
  component: () => import("../views/SMS/CampaignDetail.vue"),
  meta: {
   requiresAuth: true,
   permissions: ["sms.view", "sms.*"],
  },
  beforeEnter: (to, from, next) => {
   const services = store.state.services;
   const objService = services.find((service) => service.key === "sms");
   const userPermissions = store.state.userPermissions;

   if (objService) {
    const { enabled, can_be_enabled, key } = objService;
    if (
     enabled &&
     userPermissions &&
     userPermissions.permissions &&
     userPermissions.permissions.some((permission) => to.meta.permissions.includes(permission))
    ) {
     next();
    } else if (can_be_enabled) {
     next({ name: "EnableService", params: { serviceName: key } });
    } else {
     next({ name: "Unauthorized" });
    }
   } else {
    next({ name: "Unauthorized" });
   }
  },
 }, */
 /*   {
    path: "/",
    name: "PBXData",
    component: () => import("../views/PBXData.vue"),
    meta: {
      requiresAuth: true,
      requiresProduction: true,
    },
  }, 
 {
  path: "/pbx-extension",
  name: "PBXExtension",
  component: () => import("../components/PBXExtension.vue"),
  meta: {
   requiresAuth: true,
  },
 },*/
 {
  path: "/expert-statistics-extension",
  name: "ExpertStatisticsExtension",
  component: () => import("../components/PBX/PBXExtension.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 /*{
  path: "/pbx-level-detail/:level/:levelNumber",
  name: "PBXLevelDetail",
  component: () => import("../views/PBXLevelDetail.vue"),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },
  {
  path: "/pbx-callqueue",
  name: "PBXCallQueue",
  component: () => import("../components/PBXCallQueue.vue"),
  meta: {
   requiresAuth: true,
  },
 }, */
 {
  path: "/expert-statistics-callqueue",
  name: "ExpertStatisticsCallQueue",
  component: () => import("../components/PBX/PBXCallQueue.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 /* {
  path: "/top-20-statistics",
  name: "PBXTop20Statistics",
  component: () => import("../views/Top20Statistics.vue"),
  meta: {
   requiresAuth: true,
  },
 }, */
 //  {
 //   path: "/notifications",
 //   name: "Notifications",
 //   component: () => import("../views/Notifications.vue"),
 //   meta: {
 //    requiresAuth: true,
 //   },
 //  },
 {
  path: "/resetPassword/:passwordToken",
  name: "ResetPassword",
  component: () => import("../views/ResetPassword.vue"),
  props: true,
  meta: {
   requiresNotLogged: true,
  },
 },
 {
  path: "/login",
  name: "Login",
  component: () => import("../views/Login.vue"),
  meta: {
   requiresNotLogged: true,
  },
 },
 {
  path: "/survey/:surveyKey",
  name: "Survey",
  component: () => import("../views/Survey.vue"),
  props: true,
 },
 {
  path: "/survey-completed",
  name: "SurveyCompleted",
  component: () => import("../components/SurveyCompleted.vue"),
 },
 /*  {
    path: "/changelog",
    name: "Changelog",
    component: () => import("../views/Changelog.vue"),
    meta: {
      requiresAuth: true,
    },
  }, */
 {
  path: "/expert-statistics/:urlType",
  name: "ExpertStatistics",
  props: true,
  component: () => import("../views/PBX/ExpertStatistics.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 {
  path: "/expert-statistics-origin-detail/:urlType",
  name: "ExpertStatisticsOriginDetail",
  props: true,
  component: () => import("../views/PBX/ExpertStatisticsOriginDetail.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 {
  path: "/expert-statistics/answered/daily/:urlType",
  name: "ExpertStatisticsAnsweredDaily",
  props: true,
  component: () => import("../views/PBX/ExpertStatisticsAnswerDaily.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/expert-statistics-answered-unanswered/:urlType",
  name: "ExpertStatisticsAnsweredUnanswered",
  props: true,
  component: () =>
   import("../views/PBX/ExpertStatisticsAnswerUnansweredCalls.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 {
  path: "/expert-statistics-answered-unanswered/week-day/:urlType",
  name: "ExpertStatisticsAnsweredUnansweredWeekDay",
  props: true,
  component: () =>
   import("../views/PBX/ExpertStatisticsAnswerUnansweredCallsWeekDay.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 {
  path: "/expert-statistics-answered-unanswered/week-year/:urlType",
  name: "ExpertStatisticsAnsweredUnansweredWeekYear",
  props: true,
  component: () =>
   import("../views/PBX/ExpertStatisticsAnswerUnansweredCallsWeekYear.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 {
  path: "/expert-statistics/groups/:urlType",
  name: "ExpertStatisticsGroups",
  props: true,
  component: () => import("../views/PBX/CreatePBXGroup.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 {
  path: "/expert-statistics/reports/:urlType/:page",
  name: "ExpertStatisticsReportList",
  props: true,
  component: () => import("../views/PBX/ExpertStatisticsReportList.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 {
  path: "/expert-statistics/details/:urlType",
  name: "ExpertStatisticsQueueDetail",
  props: true,
  component: () => import("../views/PBX/ExpertStatisticsQueueTableDetail.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 {
  path: "/expert-statistics/compare-queues",
  name: "ExpertStatisticsQueueComparator",
  props: true,
  component: () => import("../views/PBX/ExpertStatisticsQueueComparator.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 {
  path: "/expert-statistics/did",
  name: "ExpertStatisticsTRTC",
  props: true,
  component: () => import("../views/PBX/ExpertStatisticsDID.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 {
  path: "/expert-statistics/third-party-numbers",
  name: "ExpertStatisticsThirdPartyNumber",
  props: true,
  component: () => import("../views/PBX/ExpertStatisticsThirdPartyNumber.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 {
  path: "/expert-statistics/caller-numbers",
  name: "ExpertStatisticsCallerNumbers",
  props: true,
  component: () => import("../views/PBX/ExpertStatisticsCallerNumbers.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 {
  path: "/expert-statistics-call-durations/:urlType",
  name: "ExpertStatisticsCallDurations",
  props: true,
  component: () => import("../views/PBX/ExpertStatisticsAnswerTime.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 {
  path: "/expert-statistics-outbound/:urlType",
  name: "ExpertStatisticsOutbound",
  props: true,
  component: () => import("../views/PBX/ExpertStatisticsOutbound.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 {
  path: "/expert-statistics-outbound/answered/:urlType",
  name: "ExpertStatisticsOutboundAnswered",
  props: true,
  component: () => import("../views/PBX/ExpertStatisticsOutboundAnswered.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 {
  path: "/expert-statistics-outbound/duration/:urlType",
  name: "ExpertStatisticsOutboundDuration",
  props: true,
  component: () => import("../views/PBX/ExpertStatisticsOutboundDuration.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 {
  path: "/expert-statistics-outbound/pbx",
  name: "ExpertStatisticsOutboundPbx",
  props: true,
  component: () => import("../views/PBX/ExpertStatisticsOutboundPbx.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 {
  path: "/expert-statistics-outbound/trunk",
  name: "ExpertStatisticsOutboundTrunk",
  props: true,
  component: () => import("../views/PBX/ExpertStatisticsOutboundTrunk.vue"),
  meta: {
   requiresAuth: true,
   requiresPermission: true,
   permissions: ["expert-statistics.view", "expert-statistics.*"],
  },
 },
 //  {
 //   path: "/expert-statistics-call-durations/waiting-time/:urlType",
 //   name: "ExpertStatisticsCallDurationsWaitingTime",
 //   props: true,
 //   component: () => import("../views/PBX/ExpertStatisticsAnswerTime.vue"),
 //   meta: {
 //    requiresAuth: true,
 //   },
 //  },
 {
  path: "/training/course/:id",
  name: "TrainingCourse",
  props: true,
  component: () => import("../views/Training/TrainingCourse.vue"),
  meta: {
   requiresAuth: true,
  },
  beforeEnter: (to, from, next) => {
   const modules = store.state.modules;
   if (modules.training) {
    next();
   } else {
    next({ name: "GetStarted" });
   }
  },
 },
 {
  path: "/training/courses",
  name: "TrainingCourses",
  props: true,
  component: () => import("../views/Training/TrainingCourses.vue"),
  meta: {
   requiresAuth: true,
  },
  beforeEnter: (to, from, next) => {
   const modules = store.state.modules;
   if (modules.training) {
    next();
   } else {
    next({ name: "GetStarted" });
   }
  },
 },
 {
  path: "/training/my-courses",
  name: "TrainingTools",
  props: true,
  component: () => import("../views/Training/TrainingTools.vue"),
  meta: {
   requiresAuth: true,
  },
  beforeEnter: (to, from, next) => {
   const modules = store.state.modules;
   if (modules.training) {
    next();
   } else {
    next({ name: "GetStarted" });
   }
  },
 },
 {
  path: "/training/quizzes",
  name: "TrainingQuizzes",
  props: true,
  component: () => import("../views/Training/TrainingQuizzes.vue"),
  meta: {
   requiresAuth: true,
  },
  beforeEnter: (to, from, next) => {
   const modules = store.state.modules;
   if (modules.training) {
    next();
   } else {
    next({ name: "GetStarted" });
   }
  },
 },
 {
  path: "/training/quiz/:id/:quizName/:uuid",
  name: "TrainingQuizDetail",
  props: true,
  component: () => import("../views/Training/QuizDetail.vue"),
  meta: {
   requiresAuth: true,
  },
  beforeEnter: (to, from, next) => {
   const modules = store.state.modules;
   if (modules.training) {
    next();
   } else {
    next({ name: "GetStarted" });
   }
  },
 },
 {
  path: "/training/quiz-group/:id",
  name: "TrainingQuizGroupDetail",
  props: true,
  component: () => import("../views/Training/QuizGroupDetail.vue"),
  meta: {
   requiresAuth: true,
  },
  beforeEnter: (to, from, next) => {
   const modules = store.state.modules;
   if (modules.training) {
    next();
   } else {
    next({ name: "GetStarted" });
   }
  },
 },
 {
  path: "/training/quiz-results/:uuid",
  name: "TrainingQuizResults",
  props: true,
  component: () => import("../views/Training/QuizResult.vue"),
  meta: {
   requiresAuth: true,
  },
  beforeEnter: (to, from, next) => {
   const modules = store.state.modules;
   if (modules.training) {
    next();
   } else {
    next({ name: "GetStarted" });
   }
  },
 },
 {
  path: "/server-error",
  name: "Error",
  props: true,
  component: () => import("../components/Error.vue"),
  meta: {
   requiresAuth: false,
  },
 },
 {
  path: "/api-not-found",
  name: "API404",
  component: () => import("../components/Error404API.vue"),
  meta: {
   requiresAuth: false,
  },
 },
 {
  path: "/get-started",
  name: "GetStarted",
  component: () => import("../components/NoAuthorization.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/page-unavailable",
  name: "StatisticsNotAvailable",
  component: () => import("../components/StatisticsNotAvailable.vue"),
  meta: {
   requiresAuth: false,
  },
 },
 {
  path: "/:pathMatch(.*)*",
  name: "404",
  component: () => import("../components/Error404.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/unauthorized",
  name: "Unauthorized",
  component: () => import("../components/Error403.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/enable-service/:serviceName",
  name: "EnableService",
  props: true,
  component: () => import("../components/EnableService.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/impersonate",
  name: "Impersonate",
  props: true,
  component: () => import("../components/impersonate.vue"),
  meta: {
   requiresAuth: true,
  },
 },
];

const router = createRouter({
 history: createWebHistory(process.env.BASE_URL),
 routes,
});

let entryUrl = null;

let blockExpertStats = false;

router.beforeEach((to, from, next) => {
 if (to.matched.some(record => record.meta.requiresAuth)) {
  if (store.state.token) {
   if (
    to.matched.some(record => record.meta.requiresPermission) &&
    store.state.userPermissions &&
    store.state.userPermissions.permissions
   ) {
    if (
     store.state.userPermissions.permissions.length > 0 &&
     store.state.userPermissions.permissions.some(
      permission => to.meta.permissions.indexOf(permission) >= 0
     )
    ) {
     if (entryUrl) {
      const url = entryUrl;
      entryUrl = null;
      return next(url);
     }
     next();
     return;
    } else {
     next({ name: "Unauthorized" });
    }
   }
   if (to.path.includes("statistics") && blockExpertStats) {
    next("/page-unavailable");
   }
   if (entryUrl) {
    const url = entryUrl;
    entryUrl = null;
    return next(url);
   }
   next();
   return;
  }
  entryUrl = to.path;
  next("/login");
 } else {
  next();
 }
});

router.beforeResolve((to, from, next) => {
 if (to.matched.some(record => record.meta.requiresAuth)) {
  if (store.state.token) {
   if (to.path.includes("statistics") && blockExpertStats) {
    next("/page-unavailable");
   }
   next();
   return;
  }
  next("/login");
 } else {
  next();
 }
});

router.beforeEach((to, from, next) => {
 if (to.matched.some(record => record.meta.requiresNotLogged)) {
  if (!store.state.token || !store.state.user) {
   next();
   return;
  }
  next("/");
 } else {
  next();
 }
});

router.beforeEach((to, from, next) => {
 if (to.matched.some(record => record.meta.requiresProduction)) {
  if (process.env.VUE_APP_STATISTICS_PRODUCTION_AVAILABLE === "true") {
   next();
   return;
  }
  next("/invoices");
 } else {
  next();
 }
});
export default router;
