<template>
 <header class="fixed pl-16 top-0 z-10 w-full bg-white shadow">
  <div class="px-4 sm:px-6 lg:px-8">
   <div class="flex justify-between items-center main_page_header">
    <div class="md:hidden flex">
     <div class="-ml-2 mr-2 flex items-center">
      <!-- Mobile menu button -->
      <button
       @click="$emit('openSubmenu')"
       class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:encom_border_input">
       <span class="sr-only">Open main menu</span>
       <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
       <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
      </button>
     </div>
    </div>
    <div
     v-if="this.account"
     class="relative inline-flex items-center px-4 py-3">
     <select
      @change="setActiveAccount($event)"
      id="currency"
      name="currency"
      class="rounded appearance-none block w-auto bg-none border bg-gray-50 pl-1 pr-6 py-1 text-sm text-gray-900">
      <option>
       <span class="">{{ activeAccount }} </span>
      </option>
      <option
       v-for="account in this.filteredAccounts"
       :key="account.id"
       :value="[account.customerAccount, account.name]">
       {{ account.name }} - {{ account.customerAccount }}
      </option>
     </select>
     <!-- <div
      class="pointer-events-none absolute inset-y-0 right-6 flex items-center">
      <ChevronDownIcon :class="`h-3 w-3 text-gray-900`" aria-hidden="true" />
     </div> -->
    </div>

    <div v-else class="relative inline-flex items-center px-4 py-3">
     <div
      :class="`rounded appearance-none block w-auto bg-none border bg-gray-50 pl-1 pr-6 py-1 text-sm text-gray-900`">
      <span class="text-center">ADMIN</span>
     </div>
    </div>

    <!--   <div class="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6 sm:ml-20 sm:mr-20">
     <div class="flex items-center px-6 py-3 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
      <div class="w-full">
       <label for="search" class="sr-only">Search</label>
       <div class="relative">
        <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
         <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
         id="search"
         name="search"
         v-model="search"
         @input="$emit('update:search', $event.target.value)"
         class="block w-full bg-white rounded-md border-1 border-gray-300 py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:text-gray-900 focus:placeholder-gray-400 sm:text-sm"
         :placeholder="$t('search')"
         type="search"
        />
       </div>
      </div>
     </div>
    </div> -->

    <div class="flex items-center">
     <div class="flex-shrink-0">
      <LanguageSelector />
     </div>
     <div class="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
      <!-- Profile dropdown -->
      <Menu as="div" class="mr-3 relative">
       <div>
        <MenuButton class="-m-1.5 flex items-center p-1.5">
         <span class="sr-only">Open user menu</span>
         <!--  <img
          class="h-8 w-8 rounded-full bg-gray-50"
          :src="`https://telecom0555-admin.bluerocktel.net/avatar/user/${user.id}`"
          alt=""
         /> -->

         <span
          class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-400">
          <span class="text-sm font-medium leading-none text-white">
           {{ initials }}
          </span>
         </span>
         <span class="hidden lg:flex lg:items-center">
          <span
           class="ml-2 text-sm font-semibold leading-6 text-gray-700"
           aria-hidden="true">
           {{ this.user.name }}
          </span>

          <ChevronDownIcon
           class="ml-2 h-5 w-5 text-gray-400"
           aria-hidden="true" />
         </span>
        </MenuButton>
       </div>
       <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95">
        <MenuItems
         class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
         <div class="px-4 py-3">
          <p class="text-sm">{{ $t("signedInAs") }}</p>
          <p class="truncate text-sm font-medium text-gray-900">
           {{ this.user.email }}
          </p>
         </div>
         <div v-if="this.adminToken" class="py-1">
          <MenuItem
           v-slot="{ active }"
           class="rounded mx-1 px-2.5 py-1.5 text-sm w-full">
           <button
            @click="impersonation()"
            :class="[
             active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
             'flex py-2 text-sm w-auto',
            ]">
            <svg
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 20 20"
             fill="currentColor"
             class="h-5 w-5 mr-2 text-gray-400 group-hover:text-gray-500 flex-none">
             <path
              d="M11 5a3 3 0 11-6 0 3 3 0 016 0zM2.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 018 18a9.953 9.953 0 01-5.385-1.572zM16.25 5.75a.75.75 0 00-1.5 0v2h-2a.75.75 0 000 1.5h2v2a.75.75 0 001.5 0v-2h2a.75.75 0 000-1.5h-2v-2z" />
            </svg>

            {{ $t("header.impersonate") }}
           </button>
          </MenuItem>
          <!--           <MenuItem
           v-slot="{ active }"
           v-if="this.adminToken && this.adminToken !== this.token"
           class="rounded mx-1 px-2.5 py-1.5 text-sm"
          >
           <a
            href="javascript:void(0)"
            @click="leaveImpersonation"
            :class="[
             active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
             'group flex px-4 py-2 text-sm',
            ]"
           >
            <svg
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 20 20"
             fill="currentColor"
             class="h-5 w-5 mr-2 text-gray-400 group-hover:text-gray-500 flex-none"
            >
             <path
              d="M11 5a3 3 0 11-6 0 3 3 0 016 0zM2.046 15.253c-.058.468.172.92.57 1.175A9.953 9.953 0 008 18c1.982 0 3.83-.578 5.384-1.573.398-.254.628-.707.57-1.175a6.001 6.001 0 00-11.908 0zM12.75 7.75a.75.75 0 000 1.5h5.5a.75.75 0 000-1.5h-5.5z"
             />
            </svg>

            {{ $t("header.leaveImpersonate") }}
           </a>
          </MenuItem> -->
         </div>
         <div class="py-1">
          <MenuItem
           v-slot="{ active }"
           class="rounded mx-1 px-2.5 py-1.5 text-sm">
           <router-link
            to="/profile"
            :class="[
             active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
             'group flex px-4 py-2 text-sm',
            ]">
            <svg
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 20 20"
             fill="currentColor"
             class="h-5 w-5 mr-2 text-gray-400 group-hover:text-gray-500 flex-none">
             <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-5.5-2.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM10 12a5.99 5.99 0 00-4.793 2.39A6.483 6.483 0 0010 16.5a6.483 6.483 0 004.793-2.11A5.99 5.99 0 0010 12z"
              clip-rule="evenodd" />
            </svg>
            {{ $t("header.yourProfile") }}
           </router-link>
          </MenuItem>
          <MenuItem
           v-slot="{ active }"
           class="rounded mx-1 px-2.5 py-1.5 text-sm">
           <a
            href="javascript:void(0)"
            @click="feedback = true"
            :class="[
             active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
             'group flex px-4 py-2 text-sm',
            ]">
            <font-awesome-icon
             icon="comment-plus"
             class="h-5 w-5 mr-2 text-gray-400 group-hover:text-gray-500 flex-none" />

            {{ $t("header.yourFeedback") }}
           </a>
          </MenuItem>
         </div>
         <div class="py-1">
          <MenuItem
           v-slot="{ active }"
           class="rounded mx-1 px-2.5 py-1.5 text-sm">
           <a
            href="javascript:void(0)"
            @click.prevent="signOut"
            :class="[
             active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
             'group flex   px-10 py-2 text-left text-sm',
            ]">
            <svg
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 20 20"
             fill="currentColor"
             class="h-5 w-5 mr-2 text-gray-400 group-hover:text-gray-500 flex-none">
             <path
              fill-rule="evenodd"
              d="M3 4.25A2.25 2.25 0 015.25 2h5.5A2.25 2.25 0 0113 4.25v2a.75.75 0 01-1.5 0v-2a.75.75 0 00-.75-.75h-5.5a.75.75 0 00-.75.75v11.5c0 .414.336.75.75.75h5.5a.75.75 0 00.75-.75v-2a.75.75 0 011.5 0v2A2.25 2.25 0 0110.75 18h-5.5A2.25 2.25 0 013 15.75V4.25z"
              clip-rule="evenodd" />
             <path
              fill-rule="evenodd"
              d="M6 10a.75.75 0 01.75-.75h9.546l-1.048-.943a.75.75 0 111.004-1.114l2.5 2.25a.75.75 0 010 1.114l-2.5 2.25a.75.75 0 11-1.004-1.114l1.048-.943H6.75A.75.75 0 016 10z"
              clip-rule="evenodd" />
            </svg>

            Sign out
           </a>
          </MenuItem>
         </div>
        </MenuItems>
       </transition>
       <!-- <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
       >
        <MenuItems
         class="z-30 origin-top-right absolute right-0 mt-2 w-52 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
         <MenuItem
          v-slot="{ active }"
          v-if="this.adminToken"
          class="rounded mx-1 px-2.5 py-1.5 text-sm"
         >
          <a
           href="javascript:void(0)"
           @click="impersonatePopup = true"
           :class="[
            active ? 'bg-gray-100' : '',
            'group flex items-center text-gray-700 z-30 flex-1',
           ]"
           ><svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 mr-3 text-gray-400 flex-none"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
           >
            <path
             stroke-linecap="round"
             stroke-linejoin="round"
             stroke-width="2"
             d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
            /></svg
           >{{ $t("header.impersonate") }}</a
          >
         </MenuItem>
         <MenuItem
          v-slot="{ active }"
          v-if="this.adminToken && this.adminToken !== this.token"
          class="rounded mx-1 px-2.5 py-1.5 text-sm"
         >
          <a
           href="javascript:void(0)"
           @click="leaveImpersonation"
           :class="[
            active ? 'bg-gray-100' : '',
            'group flex items-center text-gray-700 z-30 flex-1',
           ]"
           ><svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 mr-3 text-gray-400 flex-none"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
           >
            <path
             stroke-linecap="round"
             stroke-linejoin="round"
             stroke-width="2"
             d="M13 7a4 4 0 11-8 0 4 4 0 018 0zM9 14a6 6 0 00-6 6v1h12v-1a6 6 0 00-6-6zM21 12h-6"
            />
           </svg>
           {{ $t("header.leaveImpersonate") }}
          </a>
         </MenuItem>

         <div v-if="this.adminToken" class="relative p-1">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
           <div class="w-full border-t border-gray-300" />
          </div>
         </div>
         <MenuItem v-slot="{ active }" class="rounded mx-1 px-2.5 py-1.5 text-sm">
          <router-link
           to="/profile"
           :class="[
            active ? 'bg-gray-100' : '',
            'group flex items-center text-gray-700 z-30 flex-1',
           ]"
           ><svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 mr-3 text-gray-400 text-gray-400 flex-none"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
           >
            <path
             stroke-linecap="round"
             stroke-linejoin="round"
             stroke-width="2"
             d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
           </svg>
           {{ $t("header.yourProfile") }}
          </router-link>
         </MenuItem>
         <MenuItem v-slot="{ active }" class="rounded mx-1 px-3 py-1.5 text-sm">
          <a
           href="javascript:void(0)"
           @click.prevent="signOut"
           :class="[
            active ? 'bg-gray-100' : '',
            'group flex items-center text-gray-700 z-30 flex-1',
           ]"
           ><svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 mr-3 text-gray-400 text-gray-400 flex-none"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
           >
            <path
             stroke-linecap="round"
             stroke-linejoin="round"
             stroke-width="2"
             d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
            /></svg
           >{{ $t("header.logout") }}</a
          >
         </MenuItem>
        </MenuItems>
       </transition> -->
      </Menu>
     </div>
    </div>
   </div>
  </div>
 </header>
 <!--  <div v-if="this.adminToken">
  <impersonate
   @submitImpersonation="impersonateUser($event)"
   @signOut="this.signOut"
   v-if="impersonatePopup || this.adminToken === this.token || !this.token"
   :error="this.errorStatus"
  />
 </div> -->
 <div v-if="feedback || openFeedback">
  <FeedbackForm
   @close-form="(feedback = false), $emit('closeFeedback', false)"
   @activeBanner="$emit('activeBanner')"
   @success="$emit('success', $event)"
   @successMsg="$emit('successMsg', $event)" />
 </div>
 <AskConfirmationDialog ref="askConfirmationDialog"></AskConfirmationDialog>
</template>

<script>
import axios from "axios";
import impersonate from "./impersonate.vue";
import FeedbackForm from "./FeedbackForm.vue";
import {
 Disclosure,
 DisclosureButton,
 DisclosurePanel,
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
} from "@headlessui/vue";
import {
 SearchIcon,
 MenuIcon,
 XIcon,
 ChevronDownIcon,
} from "@heroicons/vue/outline";
import { PlusIcon, PlusSmIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";

import LanguageSelector from "./LanguageSelector.vue";
import AskConfirmationDialog from "./AskConfirmationDialog.vue";

const logo = localStorage.getItem("logo");
const avatar = localStorage.getItem("avatar");

const accountName = localStorage.getItem("accountName");
//const customerAccounts = JSON.parse(localStorage.getItem("customerAccounts"));

const showBanner = localStorage.getItem("showBanner");
const token = localStorage.getItem("token");

export default {
 props: [
  "navigation",
  "specialNavigation",
  "showSpecialNavigation",
  "openFeedback",
 ],
 components: {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  SearchIcon,
  MenuIcon,
  PlusIcon,
  XIcon,
  ChevronDownIcon,
  LanguageSelector,
  PlusSmIcon,
  impersonate,
  FeedbackForm,
  AskConfirmationDialog,
 },
 data() {
  return {
   show: false,
   avatar,
   feedback: false,
   search: "",
   quickData: [],
   logo,
   showNotifications: false,
   notificationsUnread: "",
   accounts: [],
   showBanner,
   avatar,
   impersonatePopup: false,
   notificationsFeed: [
    {
     id: 1,
     title: "New Version !",
     description: "A new version is available, see what's new.",
     isRead: false,
    },
    {
     id: 2,
     title: "New Version !",
     description: "A new version is available, see what's new.",
     isRead: false,
    },
    {
     id: 3,
     title: "New Version !",
     description: "A new version is available, see what's new.",
     isRead: false,
    },
    {
     id: 4,
     title: "New Version !",
     description: "A new version is available, see what's new.",
     isRead: false,
    },
    {
     id: 5,
     title: "New Version !",
     description: "A new version is available, see what's new.",
     isRead: false,
    },
    {
     id: 6,
     title: "New Version !",
     description: "A new version is available, see what's new.",
     isRead: false,
    },
    {
     id: 7,
     title: "New Version !",
     description: "A new version is available, see what's new.",
     isRead: false,
    },
    {
     id: 8,
     title: "New Version !",
     description: "A new version is available, see what's new.",
     isRead: false,
    },
    {
     id: 9,
     title: "New Version !",
     description: "A new version is available, see what's new.",
     isRead: false,
    },
   ],
   accountName,
   token,
  };
 },
 methods: {
  impersonation() {
   this.clearUser();
   this.$router.push("/impersonate");
  },
  clearUser() {
   localStorage.removeItem("avatar");
   localStorage.removeItem("account");
   localStorage.removeItem("customerAccounts");
   localStorage.removeItem("activeAccount");
   localStorage.removeItem("accountName");
   localStorage.removeItem("hostName");
   localStorage.removeItem("sandbox");
   localStorage.removeItem("hostTimeZone");
   localStorage.removeItem("hostContext");
   localStorage.removeItem("showBanner");
   localStorage.removeItem("queryElement");
   localStorage.removeItem("isLocal");
   localStorage.removeItem("user");
   localStorage.removeItem("pbxMap");
   localStorage.removeItem("hostName");
   localStorage.removeItem("services");
   localStorage.removeItem("userPermissions");
   localStorage.removeItem("modules");
   this.$store.dispatch("pbxMap", null);
   this.$store.dispatch("hostName", null);
   this.$store.dispatch("user", {});
   this.$store.dispatch("admin", false);
   this.$store.dispatch("userAvatar", "");
   this.$store.dispatch("account", "");
   this.$store.dispatch("services", []);
   this.$store.dispatch("userPermissions", {});
   this.$store.dispatch("modules", {});
  },
  async getAccounts() {
   if (this.token) {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/contactables`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    this.accounts = await res.data;
   }
  },
  async impersonateUser(email) {
   this.accounts = [];
   try {
    const res = await axios.post(
     `${this.$cookie.getCookie("API")}/api/v1/impersonate?email=${email}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
      },
     }
    );
    this.accounts = res.data.user.accounts;
    if (
     res.data.user.accounts.includes("ADMIN") ||
     res.data.user.activeAccount.includes("ADMIN")
    ) {
     localStorage.setItem("adminToken", res.data.token);
     this.$store.dispatch("adminToken", res.data.token);
    }
    localStorage.setItem(
     "customerAccounts",
     JSON.stringify(res.data.customerAccounts)
    );
    const activeAccount = res.data.customerAccounts.filter(
     account => account.customerAccount === res.data.user.activeAccount
    );

    localStorage.setItem(
     "activeAccount",
     activeAccount[0].name + " - " + activeAccount[0].customerAccount
    );

    localStorage.setItem("account", res.data.user.activeAccount);
    this.$store.dispatch("account", res.data.user.activeAccount);
    localStorage.setItem("token", res.data.token);
    this.$store.dispatch("token", res.data.token);
    this.$store.dispatch("user", res.data.user);
    localStorage.setItem("user", JSON.stringify(res.data.user));
    const initials = res.data.user.name
     .split(" ")
     .map(name => name.charAt(0))
     .join("");
    localStorage.setItem("initials", initials);
    localStorage.removeItem("hostName");
    //location.replace(location.href);
    this.getMyPermissions(res.data.user.activeAccount);
    this.impersonatePopup = false;
   } catch (error) {
    this.errorHandling(error);
    this.impersonatePopup = false;
   }
  },
  async leaveImpersonation() {
   try {
    this.$router.push("/");
    localStorage.setItem("token", this.adminToken);
    this.$store.dispatch("account", "");
    this.$store.dispatch("token", this.adminToken);
    localStorage.removeItem("account");
    this.impersonatePopup = false;
   } catch (error) {
    this.impersonatePopup = false;
    this.errorHandling(error);
   } finally {
    location.reload();
   }
  },
  async setActiveAccount(event) {
   let splitEvent = event.target.value.split(",");
   let id = splitEvent[0];
   let name = splitEvent[1];
   axios
    .put(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/accounts/active?activeAccount=${id}`
    )
    .then(res => {
     let activeAccount = name + " - " + id;
     localStorage.setItem("activeAccount", activeAccount);
     localStorage.setItem("account", id);
     localStorage.setItem("accountName", name);
     this.$store.dispatch("account", id);
     this.$store.dispatch("accountName", name);
     this.getMyPermissions(res.data.activeAccount);
    })

    .catch(error => {
     this.errorHandling(error);
    });
  },
  async getMyPermissions(account) {
   this.$emit("setLoading", true);
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/auth/my-permissions?customerAccount=${account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );

    const data = JSON.stringify(res.data);
    this.$store.dispatch("userPermissions", data);
    localStorage.setItem("userPermissions", data);
    this.$emit("setLoading", false);
   } catch (error) {
    console.error(error);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   } finally {
    location.replace(location.href);
   }
  },
  signOut() {
   localStorage.removeItem("token");
   localStorage.removeItem("adminToken");
   localStorage.removeItem("avatar");
   localStorage.removeItem("account");
   localStorage.removeItem("customerAccounts");
   localStorage.removeItem("activeAccount");
   localStorage.removeItem("accountName");
   localStorage.removeItem("hostName");
   localStorage.removeItem("sandbox");
   localStorage.removeItem("hostTimeZone");
   localStorage.removeItem("hostContext");
   localStorage.removeItem("showBanner");
   localStorage.removeItem("queryElement");
   localStorage.removeItem("isLocal");
   localStorage.removeItem("user");
   localStorage.removeItem("pbxMap");
   localStorage.removeItem("hostName");
   localStorage.removeItem("apiUrl");
   localStorage.removeItem("apiToken");
   localStorage.removeItem("services");
   localStorage.removeItem("userPermissions");
   localStorage.removeItem("modules");
   this.$store.dispatch("pbxMap", null);
   this.$store.dispatch("hostName", null);
   this.$store.dispatch("user", {});
   this.$store.dispatch("token", "");
   this.$store.dispatch("adminToken", "");
   this.$store.dispatch("userAvatar", "");
   this.$store.dispatch("account", "");
   this.$store.dispatch("services", []);
   this.$store.dispatch("modules", {});
   this.$router.push("/login");
  },
  checkAvatar() {
   return this.user.avatar;
  },
  notificationsUnRead() {
   const notificationsUnread = this.notificationsFeed.filter(
    notification => notification.isRead === false
   );
   return notificationsUnread;
  },
  async sendFeedback(
   id,
   msg,
   title,
   confirmationMessage,
   confirmButton,
   goBack
  ) {
   const options = {
    method: "DELETE",
    url: `${this.$cookie.getCookie("API")}/api/v1/geoRoutingLists/${id}`,
    params: { customerAccount: this.account },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .request(options)
     .then(response => {
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
     })
     .catch(error => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
 },
 computed: {
  ...mapGetters([
   "search",
   "user",
   "account",
   "activeAccount",
   "customerAccounts",
   "adminToken",
   "token",
  ]),
  filteredAccounts() {
   const customerAccounts =
    typeof this.customerAccounts === "string"
     ? JSON.parse(this.customerAccounts)
     : this.customerAccounts;
   return customerAccounts
    ? customerAccounts.filter(account => {
       return this.account !== account.customerAccount;
      })
    : "";
  },
  initials() {
   return (
    this.user.name &&
    this.user.name
     .split(" ")
     .map(name => name.charAt(0))
     .join("")
   );
  },
 },
 mounted() {
  this.getAccounts();
  this.notificationsUnRead();
 },
 watch: {
  // search: function(val) {
  //   if (val.length > 3) this.getQuick(val);
  // },
 },
};
</script>

<style></style>
